
<div fxFlex="column" fxLayoutGap="20px">

    <div fxLayout="row" fxLayoutAlign="start stretch" class="slide-container">

        <div fxFlex="30" fxLayout="column" class="slide-info">
            <div class="slide-no">
                <span>{{ currentSlideIndex + 1 }}</span>
            </div>
            <div class="slide-name">
                <span>{{ currentSlide?.customField6 }}</span>
            </div>
        </div>
    
    
        <div fxFlex="70" class="slide-description-container" (scroll)="onScroll($event)" fxLayoutGap="10px">
            <!-- <div class="title" style="text-align: center;margin-bottom: 50px;"> 
                <h1>Services</h1>
        
            </div> -->
            <div *ngFor="let slide of img;let i = index" class="slide-description">
                <div fxLayout="row" fxLayoutAlign="center center" style="height: 100%; width: 100%;margin-bottom: 40px;">
                    <div fxFlex="auto" style="text-align: center;">
    
                        <img 
                        [src]="bannerServerPath + slide.customField8" 
                        [style.height]="item.customField9 || 'auto'" 
                        [style.width]="item.customField10 || 'auto'" 
                        class="floating-image" 
                        alt="image" 
                         />
                                          </div>
                </div>
                <div>
                    <h2  [innerHTML]="slide.customField7"  [style.font-size]="item.customField4" 
                        
                    [ngStyle.lt-md]="{ 'font-size': item.customField5 }"   >{{ slide.customField7 }}</h2>
                </div>
               
            </div>
        </div>
    </div>
</div>

