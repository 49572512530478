import { Component, Input } from '@angular/core';
import { CustomFlexLayoutModule } from 'src/app/flex-layout/custom-flex-layout.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-scrolling-navigation',
  templateUrl: './scrolling-navigation.component.html',
  styleUrls: ['./scrolling-navigation.component.scss']
})
export class ScrollingNavigationComponent {
  @Input() item : any ;
  @Input() data :any;
  @Input() img: any[] = [];


  // currentSlide = this.slides[0];
  currentSlideIndex = 0;
  currentSlide: any = null;

  ngOnInit(): void {
    // We need to wait until the img data is available, 
    // then we can initialize currentSlide
    if (this.img && this.img.length > 0) {
      this.currentSlide = this.img[this.currentSlideIndex];
    }
  } 
   public bannerServerPath = environment.commonImageApi + '/commonSlider/';

 public serverPath = environment.commonImageApi + '/home/'
    // On scroll, update the slide
    onScroll(event: any): void {
      if (!this.img || this.img.length === 0) {
        return;  // Prevent further execution if img is not loaded yet
      }
  
      const scrollPosition = event.target.scrollTop;
      const slideHeight = event.target.scrollHeight / this.img.length;
      const threshold = slideHeight * 0.3; // 30% of the slide height
  
      // Calculate the index of the slide based on the scroll position and threshold
      const slideIndex = Math.floor((scrollPosition + threshold) / slideHeight);
  
      // Ensure slideIndex is within bounds and update currentSlide
      this.currentSlideIndex = Math.min(slideIndex, this.img.length - 1);
      this.currentSlide = this.img[this.currentSlideIndex] || this.img[this.img.length - 1];
    }
}
